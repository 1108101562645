<template>
  <v-card-login :caminho="logo">
    <v-card-content v-if="!logado">
      <validation-observer ref="loginForm" #default="{ invalid }">
        <b-form class="auth-login-form form-custom" @submit.prevent="validate">
          <b-form-group label-for="email" label="E-mail">
            <validation-provider
              #default="{ errors }"
              name="E-mail"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="userEmail"
                name="login-email"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Senha" label-for="senha">
            <validation-provider
              #default="{ errors }"
              name="Senha"
              rules="required"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="password"
                  v-model="password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="login-password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row class="d-flex justify-content-between container-lembrar">
            <div class="">
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Lembrar-me
              </b-form-checkbox>
            </div>
            <div class="" style="padding: 0px; font-size: 13px">
              <b-link class="" @click="forgotPass">Esqueceu sua senha?</b-link>
            </div>
          </b-row>

          <v-button
            color="primary"
            type="submit"
            :blocked="lengthScreen.width < breakPoint"
            label="Entrar"
            :disabled="invalid || enviando"
            icone="LogIn"
            :carregando="enviando"
          />
        </b-form>
      </validation-observer>
    </v-card-content>
    <b-col
      :cols="lengthScreen.width < breakPoint ? 12 : 6"
      v-else
      class="produtos-container"
    >
      <v-select-field
        label="Produto:"
        placeholder="Escolha um Produto"
        :items="produtos"
        @input="carregarProduto(produto)"
        v-model="produto"
        noOptions="Nenhum produto encontrado!"
      />
    </b-col>
  </v-card-login>
</template>

<script>
// Libs de Validação
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Libs do Bootstrap
import {
  BAlert,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BRow,
  BCol,
  VBModal
} from "bootstrap-vue";

// Cahamda de component do projeto
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

// request
import { loginJWT, setUser, loginProduto } from "@/webservice/request/auth";

import { GET_URL, caminhoLogo, breakPointScreen } from "@/plugins/contantes";

export default {
  components: {
    // BSV
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    ToastificationContent,
  },
  directives: {
    "height-fade": heightFade,
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      enviando: false,
      password: "",
      status: "",
      alertValidate: false,
      alertSubmit: false,
      dismissSecs: 30,
      dismissCountDown: 0,
      dismissSecs2: 30,
      dismissCountDown2: 0,
      erro: "",
      produtos: [],
      produto: "",
      logado: false,
      lengthScreen: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    logo() {
      return caminhoLogo();
    },
    breakPoint() {
      return breakPointScreen("md");
    },
  },
  methods: {
    forgotPass() {
      this.$router.replace("/esqueceu-senha");
    },

    getLengthScreen() {
      this.lengthScreen.width = window.innerWidth;
      this.lengthScreen.height = window.innerHeight;
    },

    validate() {
      this.$refs.loginForm.validate().then((sucess) => {
        if (sucess) {
          this.onSubmit();
        }
      });
    },
    onSubmit() {
      this.enviando = true;
      const payload = {
        email: this.userEmail,
        password: this.password,
        empresaUrl: GET_URL(),
      };
      this.$loading.start();
      loginJWT(payload)
        .then((response) => {
          this.$loading.done();
          this.enviando = false;
          const { success } = response.data;
          if (success) {
            useJwt.setToken(response.data.accessToken);
            this.produtos = response.data.resource.sistemas;
            if (this.produtos.length === 1) {
              this.carregarProduto(this.produtos[0]);
            } else {
              this.logado = true;
            }
          } else {
            const { message } = response.data
            this.$toast(
            {
              component: ToastificationContent,
              props: {
                icon: "AlertCircleIcon",
                text: message,
                title: "ATENÇÃO" ,
                variant: "warning",
                toaster: "b-toaster-bottom-right",
              },
            },
            {
              position: "bottom-right",
              timeout: 8000,
            }
          );
          }
          
        })
        .catch((error) => {
          this.enviando = false;
          this.$loading.done();
          this.$notification(error, this, ToastificationContent);
        });
    },
    setProduto(produto) {
      this.$ability.update(this.response.data.user);
      setUser(this.response);
      window.location.href = `${
        window.location.origin
      }/${produto.toLowerCase()}/home`;
    },

    carregarProduto(produto) {
      const payload = {
        checkbox_remember_me: this.status,
        email: this.userEmail,
        password: this.password,
        empresaUrl: GET_URL(),
        aplicacao: produto === "RoadShow" ? 0 : produto === "Meeting" ? 3 : 1,
      };
      this.$loading.start();
      loginProduto(payload)
        .then((response) => {
          this.$loading.done();
          if (response.data.success === true) {
            this.response = response;
            this.setProduto(produto);
          }
        })
        .catch((error) => {
          this.$loading.done();
          this.$notification(error, this, ToastificationContent);
        });
    },
  },
  created() {
    window.addEventListener("resize", this.getLengthScreen);
    this.getLengthScreen();
  },
  destroyed() {
    window.addEventListener("resize", this.getLengthScreen);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.produtos-container {
  padding: 10px 30px 10px 10px
}

.form-custom {
  padding: 1rem 0.7rem 1rem 0rem !important;
}

.container-lembrar {
  padding: 0.7rem 0.9rem !important;
  margin-bottom: 15px !important;
}

.card-body {
  padding: 0px !important;
}

.bg-color-card {
  background-color: #eff2f7 !important;
  height: 505px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-color-card-none {
  display: none;
}

.tamanho-card {
  width: 400px;
}

@media only screen and (max-width: 810px) {
  .box-logo {
    height: 100px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-custom {
    padding: 1rem !important;
  }

  .container-lembrar {
    padding: 0.9rem !important;
    margin-bottom: 15px !important;
  }

  .produtos-container {
    padding: 10px 20px 10px 20px
  }
}
</style>
